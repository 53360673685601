/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
// Base URL
export const baseUrl = 'https://admin.elife.fondationtunisie.org/api';
export const baseUploads = 'https://admin.elife.fondationtunisie.org';

// export const baseUrl = 'http://localhost:1337/api';
// export const baseUploads = 'http://localhost:1337';

export const ProgramEndPoint = (locale) =>
  `${baseUrl}/programs?fields[0]=title&fields[1]=description&fields[2]=category&fields[3]=slug&locale=${locale}`;
export const ProgramTrainingDetailEndPoint = (language, id) =>
  `${baseUrl}/programs/${id}?populate=*&locale=${language}`;
export const PartnersEndPoint = (locale) =>
  `${baseUrl}/partners?populate=*&locale=${locale}&&pagination[pagesize]=100`;
export const ProfilesEndPoint = (locale) =>
  `${baseUrl}/users-permissions/user/me/profiles?locale=${locale}`;
export const ProfileDetailsEndPoint = (name, locale) =>
  `${baseUrl}/profiles?filters[profileName][$eq]=${name}&locale=${locale}&populate=*`;
export const ProfilesFiltersEndPoint = (profileName, center, speciality) =>
  `${baseUrl}/profiles?populate[0]=profileImage&populate[1]=profileSkills?filters[centre][$in]=${center}&filters[jobTitle][$in]=${speciality}&filters[profileName][$in]=${profileName}`;

export const FavoriteProfilesEndPoint = () =>
  `${baseUrl}/users-permissions/user/me/favorites/`;

export const verifyRequestEndPoint = (id) =>
  `${baseUrl}/users-permissions/user/me/verify-request/${id}`;

export const CentersEndPoint = (locale, name) =>
  `${baseUrl}/centers?populate=*&locale=${locale}&filters[name][$eqi]=${name}`;

export const teamMembersEndPoint = (locale) =>
  `${baseUrl}/team-members?populate=*&locale=${locale}&populate=photo`;
